import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import VenueSection from "../../components/VenueSection/VenueSection";
import InviteeSection from "../../components/InviteeSection/InviteeSection";
import MyParticlesComponent from "../ParticlesComponent/ParticlesComponent";
import EventSection4 from "../../components/EventSection4/EventSection4";
import QuoteSection from "../../components/QuoteSection/QuoteSection";
import Hero from "../../components/hero/Hero";
import CoupleSection1 from "../../components/CoupleSection1/CoupleSection1";

const HomePage1 = () => {
  const containerRef = useRef(null);
  return (
    <div ref={containerRef} className={`fadeInSlow scroll-container`}>
      <MyParticlesComponent />
      <Hero />
      <CoupleSection1 />
      <QuoteSection />
      <EventSection4 haveBackground={true} />
      <VenueSection />
      <InviteeSection haveBackground={true} />
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </div>
  );
};

export default HomePage1;
