import React from "react";
import { Fade, JackInTheBox } from "react-awesome-reveal";
// import shape1 from "../../images/rsvp/shape.svg";
// import shape2 from "../../images/rsvp/shape2.svg";
// import shape3 from "../../images/rsvp/shape3.svg";
// import shape4 from "../../images/rsvp/shape4.svg";
// import shape5 from "../../images/rsvp/shape5.svg";
// import shape6 from "../../images/rsvp/shape6.svg";
import { connect } from "react-redux";

import coupleImg3 from "../../images/couple/left-invitee.svg";
import coupleImg4 from "../../images/couple/right-invitee.svg";

const renderList = (title, content, animate) => {
  return content ? (
    <Fade
      className="animate__animated animate__fadeInLeft"
      delay={animate}
      duration={animate * 5}
      triggerOnce="true"
    >
      <div className="wpo-invitee-section-wrapper">
        <p className="orangeText primaryText">{title}</p>
        <pre className="warm-regards">{content}</pre>
      </div>
    </Fade>
  ) : null;
};

const InviteeSection = (props) => {
  const {
    bestComplimentsFrom,
    warmRegards,
    invitingWithGreatPleasure,
    haveBackground,
  } = props;

  if (bestComplimentsFrom || warmRegards || invitingWithGreatPleasure) {
    return (
      <section
        className={`wpo-contact-section ${props.rClass} section-padding inviteeWrapper section`}
      >
        <div className="container">
          <div className="innerWrapper">
            {renderList("Best Compliments From", bestComplimentsFrom, 200)}
            {renderList("Warm Regards", warmRegards, 400)}
            {renderList(
              "Inviting With Great Pleasure",
              invitingWithGreatPleasure,
              600
            )}
          </div>
        </div>
        <div className="left-shape">
          <img src={coupleImg3} alt="" />
        </div>
        {haveBackground && (
          <div className="right-shape">
            <img src={coupleImg4} alt="" />
          </div>
        )}
      </section>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    bestComplimentsFrom: state.inviteDetails.invite?.bestComplimentsFrom,
    warmRegards: state.inviteDetails.invite?.warmRegards,
    invitingWithGreatPleasure:
      state.inviteDetails.invite?.invitingWithGreatPleasure,
  };
};

export default connect(mapStateToProps)(InviteeSection);
