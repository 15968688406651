export const welcomeMessageTitle =
  "Together with our family and with the blessings of {grandfather} and {grandmother}";
export const welcomeMessageSubTitle =
  "We request the honor of your presence as we unite our lives in marriage";

export const BannerSection = {
  title: "OH, Hey!",
  msg1: "It seems the invitation you're looking for is either expired or doesn't exist.",
  msg2: "But no worries! You can design your own wedding invitation in just few clicks.",
};

export const Amantrika_link = "https://amantrika.com";
export const Amantrika_Local_link = "http://localhost:3000/";

export const instagram_link =
  "https://www.instagram.com/theamantrika?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==";
export const Email_link = "mailto:theamantrika@gmail.com";

export const Themes = {
  classicElegance: "classic-elegance",
  modernChic: "modern-chic",
  indianTouch: "indian-touch",
  eternalGrace: "eternal-grace",
};

export const Cloudinary_link = "https://res.cloudinary.com/dxedclcqu";
export const CloudinaryPathPrefix =
  "https://res.cloudinary.com/dxedclcqu/image/upload/";
